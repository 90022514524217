<template>
  <!-- 上传一个文件 -->
  <el-upload
    ref="img"
    class="upload-demo i-block mr-20"
    :action="actionUrl()"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :on-error="handleError"
    :before-upload="beforeAvatarUpload"
    :on-progress="uploadProcess"
    style="margin-left: 10px;margin-right: 0px; "
  >
    <div class="lead-upload">
      <el-button size="small" type="primary" plain :loading="leadLoading" :disabled="leadLoading"> {{ leadLoading?'导 入 中':btnName }}</el-button>
      <el-progress v-if="leadLoading" :percentage="percentage" />
    </div>
  </el-upload>
</template>
<script>
export default {
  components: {},
  props: {
    fileUploadUrl: {
      type: String,
      default: ''
    }
    // type: {
    //   // video
    //   type: String,
    //   default: ''
    // }
  },
  data () {
    return {
      leadLoading: false, // 加载中 和 进度条
      btnName: '上传',
      percentage: 0 // 进度条值
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    // 导入
    actionUrl () {
      return this.fileUploadUrl
    },
    handleAvatarSuccess (res, file) {
      console.log(res+"11111111");
      if (res.code === '1') {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        var refile = res.data
        console.log(refile + '6666687788')
        this.$emit('fileByUpload', refile)
      } else {
        this.$message.error(res.msg)
      }
      this.leadLoading = false
    },
    handleError (res, file) {
      this.$message({
        message: '数据处理中，请稍后查询！',
        type: 'success'
      })
      this.leadLoading = false
    },
    beforeAvatarUpload (file) {
      console.log(file);
      this.leadLoading = true
      if (this.type === 'Excel') {
        const extension =
          file.name.substring(file.name.lastIndexOf('.')) === '.xls'
        const extension2 =
          file.name.substring(file.name.lastIndexOf('.')) === '.xlsx'
        const extension3 =
          file.name.substring(file.name.lastIndexOf('.')) === '.XLS'
        const extension4 =
          file.name.substring(file.name.lastIndexOf('.')) === '.XLSX'

        if (!extension && !extension2 && !extension3 && !extension4) {
          this.$message({
            message: '导入文件只能是Excel文件!',
            type: 'warning'
          })
          this.leadLoading = false
        }
        return extension || extension2 || extension3 || extension4
      } else if (this.type === 'video') {
        var fileSize = file.size / 1024 / 1024 < 50
        if (
          [
            'video/mp4',
            'video/ogg',
            'video/flv',
            'video/avi',
            'video/wmv',
            'video/rmvb',
            'video/mov',
            'video/mp3'
          ].indexOf(file.type) === -1
        ) {
          this.$message({
            message: '请上传正确的视频格式!',
            type: 'warning'
          })
          this.leadLoading = false
          return false
        }
        if (!fileSize) {
          this.$message({
            message: '视频大小不能超过50MB!',
            type: 'warning'
          })
          this.leadLoading = false
          return false
        }
      } else if (this.type === 'music') {
        var fileSize2 = file.size / 1024 / 1024 < 50
        console.log(file.type)
        if (
          [
            'audio/mpeg'
          ].indexOf(file.type) === -1
        ) {
          this.$message({
            message: '请上传正确的音乐格式!',
            type: 'warning'
          })
          this.leadLoading = false
          return false
        }
        if (!fileSize2) {
          this.$message({
            message: '音乐大小不能超过10MB!',
            type: 'warning'
          })
          this.leadLoading = false
          return false
        }
      }
    },
    uploadProcess (event, file, fileList) {
      this.leadLoading = true
      this.percentage = Number(file.percentage.toFixed(0))
    }
  }
}
</script>

<style  lang="scss" scoped>
.lead-upload {
  .el-progress {
    line-height: 2;
    display: inline-block;
    width: 200px;
  }
}
</style>
