<template>
  <!-- 上传图片-限一张 -->
  <div class="uploadOneImg">
    <el-upload
      class="avatar-uploader"
      :action="actionUrl()"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon" />
    </el-upload>
    <i v-if="imageUrl.length >0" class="el-icon-circle-close" @click="onRemove" />
  </div>
</template>
<script>
import {
  UploadNewsImg
} from '../api/api'
export default {
  props: {
    imgList: {
      // eslint-disable-next-line vue/require-valid-default-prop
      type: String,
      // eslint-disable-next-line vue/require-valid-default-prop
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      imageUrl: ''
    }
  },
  // created() {
  //   this.imageUrl = this.imgList
  //   console.log(this.imageUrl)
  // },
  watch: {
    imgList(newValue, oldValue) {
      this.imageUrl = newValue
      // console.log(this.imageUrl)
    }
  },
  methods: {
    actionUrl() {
      return UploadNewsImg
    },
    onRemove() {
      this.imageUrl = ''
    },
    handleAvatarSuccess(res, file) {
      // console.log(res,file);
      this.imageUrl = URL.createObjectURL(file.raw)
      console.log(this.imageUrl)
      var reImg = res.data
      console.log(reImg)
      this.$emit('uploadimgByValue', reImg)
    },
    beforeAvatarUpload(file) {
      const extension =
          file.name.substring(file.name.lastIndexOf('.')) === '.jpg'
      const extension2 =
          file.name.substring(file.name.lastIndexOf('.')) === '.png'
      const extension3 =
          file.name.substring(file.name.lastIndexOf('.')) === '.gif'
      const extension4 =
          file.name.substring(file.name.lastIndexOf('.')) === '.JPG'
      const extension5 =
          file.name.substring(file.name.lastIndexOf('.')) === '.PNG'
      const extension6 =
          file.name.substring(file.name.lastIndexOf('.')) === '.GIF'
      const extension7 =
          file.name.substring(file.name.lastIndexOf('.')) === '.JPEG'
      const extension8 =
          file.name.substring(file.name.lastIndexOf('.')) === '.jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (
        !extension &&
          !extension2 &&
          !extension3 &&
          !extension4 &&
          !extension5 &&
          !extension6 &&
          !extension7 &&
          !extension8
      ) {
        this.$message({
          message: '上传图片只能是 JPG、JPEG、PNG、GIF 格式!',
          type: 'warning'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '上传图片大小不能超过 2MB!',
          type: 'warning'
        })
      }
      return (
        (extension && isLt2M) ||
          (extension2 && isLt2M) ||
          (extension3 && isLt2M) ||
          (extension4 && isLt2M) ||
          (extension5 && isLt2M) ||
          (extension6 && isLt2M) ||
          (extension7 && isLt2M) ||
          (extension8 && isLt2M)
      )
    }
  }
}
</script>

<style less="scss">
  .el-icon-circle-close {
    font-size: 20px;
    position: absolute;
    margin-left: 98px;
    margin-top: -128px;
  }

  .uploadOneImg .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .uploadOneImg .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .uploadOneImg .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .uploadOneImg .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
</style>
