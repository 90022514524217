<template>
  <div class="detail-container">
    <!-- 顶部 -->
    <div class="detail-top">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-width="80px">
        <el-form-item label="音乐名称" prop="title">
          <el-input v-model="ruleForm.title" placeholder="请输入名称" size="small" class="sm-long"></el-input>
        </el-form-item>
        <el-form-item label="封面图片" prop="img">
          <el-checkbox-group v-show="false" v-model="ruleForm.img"></el-checkbox-group>
          <uploadOneImg :img-list="ruleForm.img" @uploadimgByValue="imgByUpload" />
        </el-form-item>
        <el-form-item label="音频文件" prop="content">
          <div class="display-row">
            <uploadOneFile v-if="fileUploadUrl!=''" :file-upload-url="fileUploadUrl" :type="'music'" @fileByUpload="fileByUpload"></uploadOneFile>
            <!-- <video v-if="ruleForm.content != ''" :src="ruleForm.content" width="260" height="200" class="ml-20 mtt-20"></video> -->
            <audio v-if="ruleForm.content!=''" controls="controls" :src="ruleForm.content" class="ml-20" />
          </div>
        </el-form-item>
        <!-- <el-form-item label="价格" prop="type">
          <el-radio v-model="ruleForm.type" :label="1">免费</el-radio>
          <el-row>
            <el-col :span="7">
              <el-radio v-model="ruleForm.type" :label="2">付费</el-radio>
            </el-col>
            <el-col v-if="ruleForm.type==2" :span="5">
              <el-form-item prop="price">
                <el-input v-model="ruleForm.price" :disabled="ruleForm.type == 1?true:false" :controls="false" size="small" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item> -->
      </el-form>
      <div>
        <el-button @click="goBack" plain size="small"><i class="el-icon-arrow-right" />返回</el-button>
      </div>
    </div>
    <div class="ml-20">
      <span class="mr-50">
        <el-button @click="goBack">取 消</el-button>
      </span>
      <span>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </div>
  </div>
</template>
<script>
import {
  getOneClocksInfo,
  multiUploadFile,
  editOneClocksInfo,
  addClocksInfo,
} from '../../../api/api'
import uploadOneFile from '../../../components/uploadOneFile.vue'
import uploadOneImg from '../../../components/uploadOneImg.vue'
export default {
  props: {},
  components: {
    uploadOneFile,
    uploadOneImg,
  },
  data() {
    var checkPirce = (rule, value, callback, text) => {
      this.common.Validate.checkPrice2(rule, value, callback, '价格')
    }
    return {
      id: this.$route.query.id,
      type: this.$route.query.type,
      ruleForm: {
        title: '', // 名称
        img: '', // 头像
        price: '', // 价格
        type: '2', // 价格类型
        content: '', // 视频文件
        category_id: '',
        category_name: '',
        content_type: '',
        diet_cate: '',
      },
      rules: {
        title: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请输入音频名称',
          },
        ],
        price: [
          {
            required: true,
            trigger: ['blur', 'change'],
            validator: checkPirce,
          },
        ],
       img: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请上传封面图片'
          }
        ],
        content: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请上传音频文件',
          },
        ],
        type: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请选择是否免费',
          },
        ],
      },
      fileUploadUrl: '',
    }
  },
  created() {
    this.fileUploadUrl = multiUploadFile
  },
  mounted() {
    if (this.id !== '') {
      this.getInfo()
    }
    // console.log(this.type + 'ttttttttt')
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'healthTrain',
        query: {
          type: this.type
        }
      })
    },
    // 上传图片
    imgByUpload(img) {
      this.ruleForm.img = img
    },
    // 上传视频
    fileByUpload(file) {
      // console.log(file+"2222222222");
      this.ruleForm.content = file
      // console.log(file)
    },
    // 获取详情信息
    getInfo() {
      this.$axios.post(getOneClocksInfo, { option_id: this.id }).then(
        (res) => {
          if (res.data.code == '1') {
            this.ruleForm = res.data.data
            
            // console.log(this.ruleForm.type);
          }
        },
        (err) => {
          console.log(err)
          // this.$message.error()
        }
      )
    },
    // 表单提交事件
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var db = this.ruleForm
          db.pid = this.type
          var url = ''
          if (this.id !== '') {
            db.option_id = this.id
            // 修改
            url = editOneClocksInfo
          } else {
            // 新增
            url = addClocksInfo
          }
          this.$axios.post(url, db).then(
            (res) => {
              if (res.data.code == '1') {
                this.goBack()
              }
            },
            (err) => {
              this.$message.error(err.msg)
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
